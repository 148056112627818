/**
 * Helper to submit forms remotely with Ajax
 */

import $ from 'jquery';
import notif from 'components/notification';
import modal from 'components/modal';
import modalConfirm from 'components/modal-confirm';

export default {
	els: {
		$modal: $('#confirm-modal')
	},

	// Default options
	defaults: {
		type: 'post',
		url: '/',
		data: '',
		showErrors: true,
		beforeSend: null,
		complete: null,
		closeModal: true,
		refresh: false
	},

	errorback: false,
	callback: false,

	init () {
		$(document).on('rebind_fw.remote-link', () => {
			this.init();
		});

		$(document).find('[data-link="remote"]').each((index, item) => {
			const params = $(item).data();

			if (typeof params.heading !== 'undefined' && typeof params.copy !== 'undefined') {
				params.confirmed = false;
			} else {
				params.confirmed = true;
			}

			params.url = $(item).attr('href');

			$(item).on('click', (e) => {
				e.preventDefault();
				e.stopPropagation();
				this.click(params);
			});
		});

		$(document).on('modal:open', () => {
			$(document).find('.modal').find('[data-link="remote"]').each((index, item) => {
				const params = $(item).data();

				if (typeof params.heading !== 'undefined' && typeof params.copy !== 'undefined') {
					params.confirmed = false;
				} else {
					params.confirmed = true;
				}

				params.url = $(item).attr('href');

				$(item).on('click', (e) => {
					e.preventDefault();
					e.stopPropagation();
					this.click(params);
				});
			});
		});
	},

	click (params, callback, errorback) {
		// Merge defaults with options
		this.options = $.extend({}, this.defaults, params);

		this.lastRequest = false;
		modalConfirm.heading = this.options.heading;
		modalConfirm.copy = this.options.copy;
		modalConfirm.confirm = this.options.confirm;
		modalConfirm.deny = this.options.deny;

		if (params.confirmed) {
			this.request(callback, errorback);
		} else {
			modalConfirm.destroy();

			modalConfirm.ask((confirmed) => {
				if (confirmed) {
					this.request(callback, errorback);
				}
			});
		}
	},

	request (callback, errorback) {
		// If there's a baseUrl and urlArray, we're dealing with submitting data multiple times (but only one callback)
		if (typeof this.options.baseUrl !== 'undefined' && typeof this.options.urlArray !== 'undefined') {
			$.each(this.options.urlArray, (i, id) => {
				// Set this to true on the last one
				// so we know when to callback (so we don't callback each time)
				if ((i + 1) === this.options.urlArray.length) {
					this.lastRequest = true;
				}

				this.makeRequest(this.options.baseUrl + id, callback, errorback);
			});
		} else {
			this.makeRequest(this.options.url, callback, errorback);
		}
	},

	makeRequest (url, callback, errorback) {
		this.callback = callback;
		this.errorback = errorback;

		$.ajax({
			dataType: 'json',
			type: this.options.type,
			url,
			data: this.options.data,
			beforeSend: this.options.beforeSend,
			complete: this.options.complete,
			error: this.error.bind(this),
			success: this.success.bind(this),
			headers: { 'X-CSRF-Token': FLYWHEEL_CSRF_TOKEN }
		});
	},

	success (data) {
		// Make sure no notifications are shown
		notif.hide();
		modal.close();

		if (
			(typeof data.result !== 'undefined' && data.result !== 'ok') ||
			(typeof data.status !== 'undefined' && data.status !== 'success')
		) {
			this.error(data);
		} else {
			if (data.reload || data.refresh || this.options.refresh) {
				window.location.reload();
			}

			if (typeof data.action !== 'undefined') {
				if (data.action.redirect) {
					window.location.href = data.action.redirect;
				}

				if (data.action.reload || data.action.refresh) {
					window.location.reload();
				}

				if (data.action.notice) {
					// Show success message
					notif.show({
						message: data.action.notice,
						type: 'success',
						float: true
					});
				}
			}

			if (this.options.redirect) {
				window.location.href = this.options.redirect;
			}

			if (this.callback) {
				if (typeof this.options.urlArray !== 'undefined') {
					if (this.lastRequest) {
						this.callback(data);
					}
				} else {
					this.callback(data);
				}

				this.errorback = false;
			}
		}
	},

	error (error) {
		let message = '';

		if (this.options.showErrors) {

			if (typeof error.action !== 'undefined') {
				if (typeof error.action.alert !== 'undefined') {
					message = error.action.alert;
				} else {
					message = 'An error occurred. Please try again.';
				}
			} else {
				message = 'An error occurred. Please try again.';
			}

			// Show error message
			notif.show({
				message,
				type: 'error',
				float: true
			});
		}

		if (this.errorback) {
			this.errorback(error);
			this.errorback = false;
		}

		if (this.options.redirect) {
			window.location.href = this.options.redirect;
		}
	}
};
