/**
 * Typeaheads
 *
 * Usage (in haml):

	.typeahead
		%form.typeahead__search{data: {type: 'collaborators'}} // where type is either 'sites' or 'collaborators'
			%input.typeahead__text{type: "text", autocomplete: "off", placeholder: "Search..."}

			%span.typeahead__search-icon
				= icon("search")


		%p Search by username or full name. You can enter an email address to send someone an invite.

		%ul.typeahead__results

	******************

	* Important note:
		This module is not responsible for handling what happens after an item from the
		typeahead results is clicked. For that, you need to watch for the 'typeahead:choose' event like so:

		$(document).on('typeahead:choose', function (e, params) { ... }

		where params contains unique information to that typeahead
 */

import $ from 'jquery';
import avatarTpl from 'templates/shared/avatars.hbs';
import userTpl from 'templates/shared/typeahead/user.hbs';
import windowLocation from 'helpers/window-location';
import overlay from './overlay';
import { preloadData } from 'helpers/preloaded';

export default {
	// Cache DOM elements
	els: {
		form: '.typeahead__search',
		input: '.typeahead__text',
		results: '.typeahead__results',
		thisResult: ''
	},

	// Set some defaults
	defaults: {
		openClass: 'active',
		minCharacter: 2
	},

	// Kick things off
	init () {
		this.events();
	},

	// Watch for events
	events () {
		$(document)
			.on('keydown', (e) => {
				// If escape key
				if (e.key === 'Escape') {
					this.close();
				}
			})

			.on('submit', '.typeahead__search', (e) => {
				e.stopPropagation();
				e.preventDefault();
				return false;
			})

			.on('click', () => {
				// Close typeahead on outside clicks
				$(this.els.form).removeClass('open');
				$(this.els.results).removeClass('open');

				const overlayElem = document.querySelector('.overlay');
				if (overlayElem && overlayElem.classList.contains('--search')) {
					overlay.close();
				}

				$(this.els.input).each((index, element) => {
					if (!element.classList.contains('js-persistent')) {
						// Reset typeahead
						element.value = '';
						element.blur();
					}
				});
			})

			.on('click', '.typeahead__search', (e) => {
				// Allow click
				e.stopPropagation();
			})

			// Unbind for things generated by Backbone
			.off('click', '.typeahead__results li a')

			.on('click', '.typeahead__results li a', (e) => {
				e.stopPropagation();

				this.els.thisResult = $(e.currentTarget).parents('.typeahead__results');
				this.els.thisParent = this.els.thisResult.siblings('.typeahead__search');

				// Get current type
				this.type = this.els.thisParent.data('type');

				// Send event data to GTM
				window.dataLayer.push({event: 'header-search-click'});

				if (this.type === 'collaborators' || this.type === 'members' || this.type === 'white_label_sites') {
					this.els.thisResult.addClass('loading');
					this.handleItemClick(e);
				}
			});

		// Handle typeahead key presses
		$(this.els.input).on('keydown', (e) => {
			// Set DOM elements relative to current input
			this.els.thisParent = $(e.currentTarget).parents('.typeahead__search');
			this.els.thisResult = $(e.currentTarget).parents().siblings('.typeahead__results');

			// Get current type
			this.type = this.els.thisParent.data('type');

			this.handleTypeaheadKeyPresses(e);
		});

		// Close typeahead results if modal is closed
		$(document).on('modal:close', () => {
			this.close();
		});
	},

	// Do the typeahead filtering
	handleTypeaheadKeyPresses (e) {
		const navItem = this.els.thisResult.find('li');

		this.curHighlight = this.els.thisResult.find('li.active');

		// down key
		if (e.key === 'ArrowDown') {
			if (this.curHighlight.length) {
				// if one is already highlighted

				if (this.curHighlight.next().length) {
					this.curHighlight.removeClass(this.defaults.openClass).next().addClass(this.defaults.openClass);
				} else {
					// else, highlight the first one
					navItem.first().addClass(this.defaults.openClass);
				}
			}
		// up key
		} else if (e.key === 'ArrowUp') {
			// prevent cursor from moving
			e.preventDefault();

			if (this.curHighlight.length) {
				// if one is already highlighted
				if (this.curHighlight.prev().length) {
					this.curHighlight.removeClass(this.defaults.openClass).prev().addClass(this.defaults.openClass);
				} else {
					// else, highlight the first one
					navItem.first().addClass(this.defaults.openClass);
				}
			}
		// enter key
		} else if (e.key === 'Enter') {
			let location;

			e.preventDefault();
			e.stopPropagation();

			if (this.curHighlight.length) {
				if (this.type === 'white_label_sites') {
					this.curHighlight.find('a').click();
				} else {
					// Send event data to GTM
					window.dataLayer.push({type: 'header-search-keydown'});
					location = this.curHighlight.find('a').attr('href');

					// Take user to site URL when hitting enter
					windowLocation.set(location);
				}
			} else {
				import(/* webpackChunkName: "gsap" */ 'gsap').then((gsap) => {
					// bounce the results
					gsap.gsap.to(this.els.results, { scale: 1.075, ease: gsap.Bounce.easeInOut, duration: 0.05 });
					gsap.gsap.to(this.els.results, { scale: 1, delay: 0.1, duration: 0.0125 });
				});
			}
		} else if (e.key === 'Tab') {
			this.close();
		} else {
			// If not up, down, or esc keys, go ahead search
			import(/* webpackChunkName: "underscore" */ 'underscore').then((_) => {
				this.makeRequest(e.target);
			});
		}
	},

	validateEmail (email) {
		const re = /^[A-Z0-9._%+-]+@[A-Z0-9][A-Z0-9.-]+\.[A-Z]{2,}$/i;

		return re.test(email);
	},

	// Handle what hapens when you click an item from the typeahead results
	handleItemClick (e) {
		const el = $(e.currentTarget);

		let dataObject = {};

		e.preventDefault();

		if (this.isEmail) {
			dataObject = {
				email: this.els.thisParent.find('.typeahead__text').val()
			};
		} else if (el.data('type') === 'org') {
			dataObject = {
				organization_id: el.data('id')
			};
		} else if (el.data('type') === 'wl') {
			dataObject = {
				site_id: el.data('id'),
				site_name: el.data('name'),
				domain: el.data('domain'),
				thumb: el.data('thumb')
			};
		} else {
			dataObject = {
				user_id: el.data('id')
			};
		}

		// Clear out input
		$(this.els.input).val('');

		// Trigger a custom event
		// so we can watch for it in other modules.
		// This way, this typeahead.js module is not
		// directly responsible for handling what happens when an
		// item from the typeahead results is clicked.
		$(document).trigger('typeahead:choose', dataObject);
	},

	// Go out and get data from the server
	makeRequest: _.debounce(function (e) {
		const curVal = $(e).val();

		let searchUrl = '';
		let searchIncludes = '';

		let label = 'Finding results...';

		let data = {
			q: $(e).val()
		};

		// Set proper request URL based on the type
		switch (this.type) {
			case 'sites':
				searchUrl = '/site_search';
				label = 'Finding sites...';
				break;
			case 'white_label_sites':
				const form = $(`${this.els.form}[data-type="white_label_sites"]`);
				searchUrl = form.data('searchUrl');
				label = 'Finding sites...';
				this.defaults.minCharacter = 0;
				break;
			case 'collaborators':
				label = 'Checking for email address...';
				break;
			case 'members':
				label = 'Checking for email address...';
				break;
		}

		// Show searching status while fetching
		this.els.thisResult.empty();
		this.els.thisResult.addClass('open');
		this.els.thisResult.append('<li class="typeahead__empty"><p>' + label + '</p></li>');

		// Proceed only if 3 or more characters are entered
		if (curVal.length > this.defaults.minCharacter) {
			// Don't make request if current value is an email
			if (this.validateEmail(curVal) === true) {
				// Open up the result element
				this.els.thisResult.addClass('open');

				// Set email to true so we can send it in the jQuery event
				this.isEmail = true;

				// Clear out results list
				this.els.thisResult.empty();
				this.showEmailResult(curVal);
			} else {
				this.isEmail = false;
				if (searchUrl === '') {
					return;
				}

				// Make request to server!
				$.ajax({
					dataType: 'json',
					type: 'get',
					url: searchUrl,
					data: data,
					success: (data) => {
						// Run through result and divy things up
						this.processResult(data);
					}
				});
			}
		} else {
			// If less than 3 characters, close things down...
			this.els.thisResult.empty();
			this.els.thisResult.removeClass('open');
		}
	}, 250),

	// Process the result once data is recieved
	processResult (data) {
		// First, clear out list
		this.els.thisResult.empty();

		if (this.type === 'collaborators' || this.type === 'members') {
			this.showCollabsResult(data);
		} else if (this.type === 'sites') {
			this.showSitesResult(data);
		} else if (this.type === 'white_label_sites') {
			this.showSitesResult(data, true);
		}

		// Handle active item when hovering with mouse
		this.els.thisResult.find('li').on('mouseover', (e) => {
			this.els.thisResult.find('li').removeClass(this.defaults.openClass);
			$(e.currentTarget).addClass(this.defaults.openClass);
		});
	},

	// Build email list
	showEmailResult (email) {
		const avatar = avatarTpl({
			account: {
				'initials': email.substring(0, 2),
				'avatar': {
					'large_url': `https://www.gravatar.com/avatar/${btoa(email)}?d=blank`
				}
			},
			size: 'tiny',
			classes: 'typeahead__avatar',
			dimensions: 35,
			letter: email.substring(0, 1)
		});

		const element = `<li class="active">
			<a href="#" data-id="${email}">
				${avatar}
				<span>${email}</span>
				<span class="button --secondary typeahead__button">Invite</span>
			</a>
		</li>`;

		this.els.thisResult.append(element);
	},

	// Build collaborators list
	showCollabsResult (data) {
		if (data.users || data.organizations) {

			// Combine users and organizations into same array
			if (data.organizations) {
				let tempData = data.organizations.array;

				if (data.users) {
					tempData = data.users.array.concat(data.organizations.array);
				}

				data = tempData;
			} else {
				data = data.users.array;
			}

			// Open up the result element
			this.els.thisResult.addClass('open');

			// Loop through the data object
			$.each(data, (index, result) => {
				// var className = (index === 0 ? self.defaults.openClass : '');
				// var avatarUrl = '';
				const account = result;

				let avatar = '';
				let template = '';

				// Set whether it's on org or user.
				// A rather crappy way to do this,
				// but since orgs don't have first names we can check against that
				account.name = result.name ? result.name : `${result.first_name} ${result.last_name}`;

				account.type = account.is_org ? 'org' : 'user';

				avatar = avatarTpl({
					account,
					size: 'tiny',
					classes: 'typeahead__avatar',
					dimensions: 35,
					letter: account.initials.substring(0, 1)
				});

				template = userTpl({
					account,
					avatar
				});

				// Actually append the list item to the DOM
				this.els.thisResult.append(template);
			});
		} else {
			this.els.thisResult.removeClass('open');
		}
	},

	// Build sites list (consumed in both main site search and white label bulk site search)
	showSitesResult (data, wl) {
		// Open up the result element
		this.els.thisResult.addClass('open');

		if (data.length) {
			// Loop through the data object
			$.each(data, (index, result) => {
				const className = (index === 0 ? this.defaults.openClass : '');
				const avatarUrl = result.avatar.avatar.list.url;

				// Feature gate & defaultGrowthSuiteOrgUsername
				const isNewSitesPageEnabled = preloadData().user.is_new_sites_page_enabled;

				let listItem;

				// Modify template a bit if it's white label
				if (wl) {
					listItem = `<li class="${className}">
						<a href="${result.url}"
							data-type="wl"
							data-id="${result.id}"
							data-name="${result.name}"
							data-domain="${result.domain}"
							data-thumb="${result.avatar.avatar.url}">
							<img src="${avatarUrl}" width="62" height="34" class="typeahead__image">
							<span>${result.name}</span>
						</a>
					</li>`;
				} else if (result.wpengine && !isNewSitesPageEnabled) {
					listItem = `<li class="${className}">
						<a href="${result.url}" data-slug="${result.slug}" data-name="${result.name}" class="typeahead__hoverable" target="_blank">
							<img src="${avatarUrl}" width="62" height="34" class="typeahead__image">
							<div class="text-overflow">
								<div class="text-overflow">${result.name}</div>
								<div class="text-overflow display-flex --align-center">
									<span class="text-overflow">${result.default_domain}</span>
								</div>
							</div>
							<div class="typeahead__hidden">
								<span>OPEN IN WP ENGINE</span>
								<span class="icon vue-icon --ml-10"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 13.99 13.989" width="13.99" height="13.989"><path d="M7.994,13.99H4a4.009,4.009,0,0,1-4-4V6A4.009,4.009,0,0,1,4,2H5A.944.944,0,0,1,6,3,.944.944,0,0,1,5,4H4A2,2,0,0,0,2,6v4a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2v-1a.944.944,0,0,1,1-1,.944.944,0,0,1,1,1v1A4.009,4.009,0,0,1,7.994,13.99ZM13.99,6V1a.944.944,0,0,0-1-1h-5a.944.944,0,0,0-1,1,.944.944,0,0,0,1,1h4V6a1,1,0,1,0,2,0ZM6.3,9.093l6.2-6.1a.966.966,0,0,0,0-1.4.966.966,0,0,0-1.4,0l-6.2,6.1a.966.966,0,0,0,0,1.4,1.079,1.079,0,0,0,.7.3A1.079,1.079,0,0,0,6.3,9.093Z"></path></svg></span>
							</div>
						</a>
					</li>`;
				} else {
					listItem = `<li class="${className}">
						<a href="${result.appUrl}" data-slug="${result.slug}" data-name="${result.name}" class=${result.wpengine && 'typeahead__hoverable'} target=${result.wpengine && '_blank'}>
							<img src="${avatarUrl}" width="62" height="34" class="typeahead__image">
							<div class="text-overflow">
								<div class="text-overflow">${result.name}</div>
								<div class="text-overflow display-flex --align-center">
									<span class="icon vue-icon ${!result.cloud && 'hidden'}"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 12.5" width="22" height="12.5"><path d="M17.25,3.89a7,7,0,0,0-6-3.89C8.31,0,5.71,2.14,4.57,5.41A5.17,5.17,0,0,0,.14,11.74a1,1,0,0,0,1,.76H20.69a1,1,0,0,0,.94-.65A6,6,0,0,0,22,9.73,5.8,5.8,0,0,0,17.25,3.89Zm2.68,6.61H2A3.07,3.07,0,0,1,5,7.37a1.91,1.91,0,0,1,1.88,1.2,1,1,0,0,0,1.79-.89,4.06,4.06,0,0,0-2-2C7.52,3.44,9.29,2,11.26,2A4.8,4.8,0,0,1,15,4a5.63,5.63,0,0,0-3.31,2.67,1,1,0,0,0,1.75,1A3.53,3.53,0,0,1,16.2,5.83h.25A3.75,3.75,0,0,1,20,9.73,3.87,3.87,0,0,1,19.93,10.5Z"></path></svg></span>
									<div class="vertical-divider --mini --ml-10 --mr-10 ${!result.cloud && 'hidden'}"></div>
									<span class="text-overflow">${result.default_domain}</span>
								</div>
							</div>
						</a>
					</li>`;
				}

				// Actually append the list item to the DOM
				this.els.thisResult.append(listItem);
			});
		} else {
			this.els.thisResult.append('<li class="typeahead__empty"><p>No sites found</p></li>');
		}
	},

	// Shut 'er down
	close () {
		// Reset typeahead
		const input = document.querySelector(this.els.input);

		if (input) {
			input.value = '';
			input.blur();

			// Close results
			const results = document.querySelector(this.els.results);
			while (results.firstChild) {
				results.removeChild(results.firstChild);
			}
			results.classList.remove('open', 'loading');
		}
	}
};
